import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import PropTypes from 'prop-types';
import MenuBar from '../partials/global/MenuBar';
import PageContent from '../partials/sections/PageContent';

function BlogPost(props) {
  const {
    data: {
      blogPost,
    },
  } = props;
  const {
    pageContent: {
      pageSection,
    },
  } = blogPost;

  return (
    // Bug - Layout breaks when section is replaced with fragment
    <section className="full-height-section">
      <Seo post={blogPost} />
      <MenuBar />
      <PageContent pageSections={pageSection} />
    </section>
  );
}

export const query = graphql`
  query($postId: String){
    blogPost: wpPost(id: {eq: $postId}) {
      seo {
        ...YoastPageSEOContent
      }
      id
      slug
      title
      pageContent {
        pageSection {
          ... on WpPost_Pagecontent_PageSection_BannerSection {
            fieldGroupName
            bannerContent
            bannerSlides {
              fieldGroupName
              slideContent
              slideImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          ... on WpPost_Pagecontent_PageSection_Section {
            backgroundColor
            fieldGroupName
            sectionColumn {
              ... on WpPost_Pagecontent_PageSection_Section_SectionColumn_ImageBlock {
                caption
                columnWidth
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
                imageUrl
                fieldGroupName
              }
              ... on WpPost_Pagecontent_PageSection_Section_SectionColumn_TextBlock {
                columnWidth
                description
                fieldGroupName
              }
              ... on WpPost_Pagecontent_PageSection_Section_SectionColumn_EmbedBlock {
                caption
                columnWidth
                embedCode
                fieldGroupName
              }
            }
          }
        }
      }
      
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.shape({
    blogPost: PropTypes.shape({
      pageContent: PropTypes.shape({
        pageSection: PropTypes.arrayOf(PropTypes.shape({})),
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
export default BlogPost;
