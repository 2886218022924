import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/scss';
// eslint-disable-next-line import/no-unresolved
import 'swiper/scss/pagination';
import * as styles from './PageBanner.module.scss';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);
function PageBanner(props) {
  const {
    data: {
      bannerContent: globalBannerContent,
      bannerSlides,
    },
  } = props;
  const renderSlide = (slide, index) => {
    const contentWrapClass = globalBannerContent ? styles.invisibleContent : styles.visibleContent;
    const bannerContent = globalBannerContent || slide.slideContent || '';
    return (
      <SwiperSlide key={index.toString()}>
        <div className={styles.slideImageWrap}>
          <GatsbyImage
            className={styles.slideImage}
            image={getImage(slide.slideImage.localFile)}
            alt={slide.slideImage.altText}
          />
        </div>

        <div className={[styles.slideContentWrap, contentWrapClass].join(' ')}>
          <div
            className={styles.slideContent}
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: bannerContent }}
          />
        </div>

      </SwiperSlide>
    );
  };

  return (
    <div className={styles.pageBannerWrap}>
      <Swiper
        autoplay={{ delay: 3000 }}
        className={styles.bannerSlider}
        pagination={{ clickable: true }}
      >
        {bannerSlides && bannerSlides.map((slide, index) => renderSlide(slide, index))}

        {globalBannerContent && (
        <div slot="container-end" className={styles.globalContentWrap}>
          <div
            className={styles.globalContent}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: globalBannerContent }}
          />
        </div>
        )}

      </Swiper>
    </div>
  );
}
PageBanner.propTypes = {
  data: PropTypes.shape({
    bannerContent: PropTypes.string,
    bannerSlides: PropTypes.arrayOf(PropTypes.shape({
      slideContent: PropTypes.string,
      slideImage: PropTypes.shape({
        localFile: PropTypes.shape({}).isRequired,
        altText: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,

};
export default PageBanner;
