import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './EmbedColumn.module.scss';

function EmbedColumn(props) {
  const {
    data: {
      columnWidth,
      embedCode,
      caption,
    },
  } = props;

  const getColumnClass = (colWidth) => {
    switch (colWidth) {
      case 'two_out_of_12':
        return 'col-12 col-md-2';
      case 'four_out_of_12':
        return 'col-12 col-md-4';
      case 'six_out_of_12':
        return 'col-12 col-md-6';
      case 'eight_out_of_12':
        return 'col-12 col-md-8';
      case 'ten_out_of_12':
        return 'col-12 col-md-10';
      case 'twelve_out_of_12':
        return 'col-12';
      default:
        return 'col-12';
    }
  };

  return (
    <div className={getColumnClass(columnWidth)}>
      <div className={styles.embedContainer}>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={styles.embedWrap} dangerouslySetInnerHTML={{ __html: embedCode }} />
        {caption && (
        // eslint-disable-next-line react/no-danger
        <div className={styles.captionWrap} dangerouslySetInnerHTML={{ __html: caption }} />
        )}
      </div>
    </div>
  );
}
EmbedColumn.propTypes = {
  data: PropTypes.shape({
    columnWidth: PropTypes.string.isRequired,
    embedCode: PropTypes.string.isRequired,
    caption: PropTypes.string,
  }).isRequired,

};
export default EmbedColumn;
