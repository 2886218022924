import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './TextColumn.module.scss';

function TextColumn(props) {
  const {
    data: {
      columnWidth,
      description,
    },
  } = props;

  const getColumnClass = (colWidth) => {
    switch (colWidth) {
      case 'two_out_of_12':
        return 'col-12 col-md-2';
      case 'four_out_of_12':
        return 'col-12 col-md-4';
      case 'six_out_of_12':
        return 'col-12 col-md-6';
      case 'eight_out_of_12':
        return 'col-12 col-md-8';
      case 'ten_out_of_12':
        return 'col-12 col-md-10';
      case 'twelve_out_of_12':
        return 'col-12';
      default:
        return 'col-12';
    }
  };

  return (
    <div className={getColumnClass(columnWidth)}>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.descriptionWrap} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}

TextColumn.propTypes = {
  data: PropTypes.shape({
    columnWidth: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};
export default TextColumn;
