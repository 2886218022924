import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './ImageColumn.module.scss';

function ImageColumn(props) {
  const {
    data: {
      columnWidth,
      caption,
      imageUrl,
      image,
    },
  } = props;

  const getColumnClass = (colWidth) => {
    switch (colWidth) {
      case 'two_out_of_12':
        return 'col-12 col-md-2';
      case 'four_out_of_12':
        return 'col-12 col-md-4';
      case 'six_out_of_12':
        return 'col-12 col-md-6';
      case 'eight_out_of_12':
        return 'col-12 col-md-8';
      case 'ten_out_of_12':
        return 'col-12 col-md-10';
      case 'twelve_out_of_12':
        return 'col-12';
      default:
        return 'col-12';
    }
  };

  const renderImage = () => {
    if (imageUrl) {
      return (
        <a className={styles.imageLink} href={imageUrl}>
          <GatsbyImage image={getImage(image.localFile)} alt={image.altText} />
          { caption && (
            // eslint-disable-next-line react/no-danger
            <div className={styles.captionWrap} dangerouslySetInnerHTML={{ __html: caption }} />
          ) }

        </a>
      );
    }
    return (
      <>
        <GatsbyImage image={getImage(image.localFile)} alt={image.altText} />
        {caption && (
        // eslint-disable-next-line react/no-danger
        <div className={styles.captionWrap} dangerouslySetInnerHTML={{ __html: caption }} />
        ) }
      </>
    );
  };

  return (
    <div className={getColumnClass(columnWidth)}>
      <div className={styles.imageWrap}>{renderImage()}</div>
    </div>
  );
}

ImageColumn.propTypes = {
  data: PropTypes.shape({
    columnWidth: PropTypes.string.isRequired,
    caption: PropTypes.string,
    imageUrl: PropTypes.string,
    image: PropTypes.shape({
      altText: PropTypes.string.isRequired,
      localFile: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ImageColumn;
